//============================
//Cuts section
//============================

var activeCut = "";
var currentCutID = jQuery("#current-item-id").val();
 
jQuery(function () {

if (jQuery('.cutsNav').attr('id')) {
    // recalculate image map based on image size on pig cut page
    if (jQuery('img[usemap]').length != 0) {
        jQuery('map').imageMapResize();
        jQuery(window).resize(function () {
            jQuery('map').imageMapResize();
        });
    }
     
    // setup active cut for return state on mouse events
    activeCut = mainCuts[jQuery('.cutsNav').attr('id')];

    if (activeCut == undefined) {
        activeCut = {
            "title": jQuery('#cut-title').text(),
            "image": jQuery('.thumb-nav img').attr('src'),
            "abstract": jQuery('#cut-abstract').text()
        }
    }
    // Add mouseover/leave actions
    //jQuery('map#cuts-map area').mouseenter(function () {
    //    jQuery('#cuts-image').attr('src', mainCuts[this.id.substring(5)].image);
    //    console.log(mainCuts[this.id.substring(5)]);
    //    showCut(mainCuts[this.id.substring(5)]);
            
    //});
    jQuery('#cuts-image').mouseenter(function () {
        jQuery('map#cuts-map area').mouseenter(function () {
            jQuery('#cuts-image').attr('src', mainCuts[this.id.substring(5)].image);
            console.log(mainCuts[this.id.substring(5)]);
            showCut(mainCuts[this.id.substring(5)]);

        });
    });



    jQuery('map#cuts-map area').mouseleave(function () {
        showCut(activeCut)
    });

    jQuery('map#cuts-map area').click(function () {
            
        //if (activeCut.id == 'FE807F2AE0A7471E85836647A436369A') {
        window.location.href = this.href;
        //}

        if (jQuery('#cuts-image').attr('src') != jQuery('.cuts-image-thumbnail').attr('src')) {
            jQuery('.cuts-image-thumbnail').addClass('cuts-image-thumbnail-show');
        }
        else {
            jQuery('.cuts-image-thumbnail').removeClass('cuts-image-thumbnail-show');
        }
        return false
    });

    jQuery(".cuts-image-thumbnail").removeAttr("height");







    jQuery('.thumb-nav img').click(function () {
        jQuery('.cuts-image-thumbnail').addClass('thumbnail-open');
        jQuery('.thumb-nav').addClass('thumb-nav--open').find('.thumb-close').addClass('thumb-close--open');
      //  jQuery('.cut-photo').hide();
        jQuery(this).attr('usemap', '#cuts-map');
        jQuery('map').imageMapResize();

    });
    jQuery('.thumb-close').click(function () {
        jQuery('.thumb-nav').removeClass('thumbnail-open');
        jQuery('.thumb-nav').removeClass('thumb-nav--open').find('.thumb-close').removeClass('thumb-close--open')
        jQuery('.thumb-nav img').attr('usemap', '');
       // jQuery('.cut-photo').show();
        showCut(activeCut); 
    });
        
    function showCut(cutObj) {
        if (cutObj) {
         //   console.log(currentCutID);
         //   console.log(cutObj.id);
            jQuery("#cut-link").hide();
            jQuery('#cut-title').html(cutObj.title);
            jQuery('#cut-abstract').html(cutObj.abstract.replace(/&quot;/g, ''));
            if (jQuery('.thumb-nav')) {
                jQuery('.thumb-nav img').attr('src', cutObj.image);
            }
            if (currentCutID == cutObj.id) {
                jQuery("#cut-link").show();

            }
        }
            
    }

      
    jQuery(window).load(function () {
        var noPhoto = jQuery("#noPhoto").val();
        if (noPhoto == "1") {
            jQuery('.cuts-image-thumbnail').addClass('thumbnail-open');
            
            jQuery('.thumb-nav').addClass('thumb-nav--open').find('.thumb-close').addClass('thumb-close--open');
            jQuery('.thumb-close').hide();
            jQuery(this).attr('usemap', '#cuts-map');
            jQuery('map').imageMapResize();
        }
    });



   
}

jQuery(document).on('click', 'a#cut-link', function (event) {
    event.preventDefault();

    jQuery('html, body').animate({
        scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top
    }, 500);
});


    // new cut


jQuery(".section-menu-toggle, .megamenu-close-btn").click(function () {
    mm = jQuery(".mega-menu");

    if (mm.hasClass("mega-menu-open")) {
        mm.attr("class", "mega-menu");
        console.log("remove " + mm.attr("class"));
    }
    else {
        mm.addClass("mega-menu-open");
        console.log("add " + mm.attr("class"));
    }
});


jQuery(".prepartion-tab").click(function () {
    var cur = jQuery(this);
    var id = "#" + cur.attr("data-el");

    jQuery(".prepartion-tab-content").hide();

    jQuery(".prepartion-tab").removeClass("active");

    jQuery(cur).addClass("active");
    var h = jQuery(id).height() + 160;
    jQuery(".prepartion-tab-container").attr('style', 'min-height:' + h + 'px')
    jQuery(id).show();

});

var h = jQuery(".prepartion-tab-content-open").height() + 160;
jQuery(".prepartion-tab-container").attr('style', 'min-height:' + h + 'px')


jQuery('#skank-front, #skank-back').mouseover(function () {
    jQuery('#skank-front, #skank-back').addClass('activepath');
});

jQuery('#skank-front, #skank-back').mouseleave(function () {
    jQuery('#skank-front, #skank-back').removeClass('activepath');
});



var url = window.location.search;
if (url.match("cut")) {
    if (url.match("cut").length > 0) {
        var id = location.search.split("&")[0].replace("?", "").split("=")[1];

        jQuery("svg a").each(function () {
            var foundURL = this.href.baseVal.match(id);

            if (foundURL) {
                jQuery(this).addClass("selectedpath");
            }

        })

    }
}



    // cut page


var url = window.location.search;
if (url.match("cut")) {
    if (url.match("cut").length > 0) {
        var id = location.search.split("&")[0].replace("?", "").split("=")[1];

        jQuery("svg a").each(function () {
            var foundURL = this.href.baseVal.match(id);

            if (foundURL) {
                jQuery(this).addClass("selectedpath");
            }

        })

    }
}



    // active pig cut on small pig 
// if (window.location.href.includes("goderaavarer")) {

    
if (window.location.hash.indexOf("goderaavarer") >= 0) {
    var section = window.location.href.split('udskaeringer/')[1].split('/')[0]
    console.log(section)
    getActiveCut(section);

} else if (window.location.hash.indexOf("voresmad") >= 0) {
    if (window.location.href.includes("gris")) {
        var animal = "pig";
        var section = window.location.href.split('udskaeringer/gris/')[1].split('/')[0];
    }
    else if (window.location.href.includes("okse")) {
        var animal = "beef";
        var section = window.location.href.split('udskaeringer/okse/')[1].split('/')[0];
        
    }
    console.log(section)
    getActiveCut(section,animal);
}

function getActiveCut(section,animal) {
    jQuery("svg#" + animal + " a").children().each(function () {
        
        if (section.includes(this.id)) { 
            var id = "#" + this.id;
            jQuery(id).addClass("activepath");
        }
    });
}

//var section = window.location.href.split('udskaeringer/gris/')[1].split('/')[0]
//if (section == "moerbrad-af-gris") section = "morbrad";
//if (section == "skank-af-gris") section = "skank-front";

//jQuery("svg#pig a").children().each(function () {
//    if (section.includes(this.id)) {
        
//        var id = "#" + this.id;
//        if (id == "#skank-front") jQuery("#skank-back").addClass("activepath");
//        jQuery(id).addClass("activepath");
//    }  
//});

});

